<template>
  <div class="bottom">
    <div class="tile_wrap">
      <div class="flex-r">
        <div class="img flex-r">
          <img src="@/assets/img/index/pin.png" />
        </div>
        <span>品牌专区</span>
      </div>
      <div class="right" @click="toBrand">
        <span style="font-size: 16px; cursor: pointer; font-weight: bold; color: #f14948">更多</span>
        <div class="more">
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="top_img">
        <div class="img" v-for="item in brand_adv" :key="item.id">
          <img :src="item.image.url" alt="" @click="click(item)" />
        </div>
      </div>
      <!-- 版本一 -->
      <!-- <div class="wrapper">
        <div class="img" v-for="item in brand_swiper" :key="item.img">
          <img :src="item.img" alt="" @click="handleJump(item.jump)" />
        </div>
        <div class="flex_c_c c_p img" style="height: 100px; width: 100px" @click="handleMore"><i class="el-icon-refresh-left"></i>换一批</div>
      </div> -->
      <!-- demo测试 -->

      <div class="swiper" style="margin-top: 20px">
        <div class="swiper-wrapper">
          <div class="swiper-slide" style="margin-right: 10px" v-for="(item, i) in brand_swiper" :key="i" @click="handleJump(item.jump)">
            <img style="width: 100px; height: 100px; margin-right: 10px; border: 1px solid #f2f2f2; border-radius: 8px" :src="item.img" alt="" />
          </div>
          <div class="swiper-slide"></div>
          <div class="swiper-slide"></div>
          <div class="swiper-slide"></div>
          <div class="swiper-slide"></div>
          <div class="swiper-slide"></div>
          <div class="swiper-slide"></div>
          <div class="swiper-slide"></div>
          <div class="swiper-slide"></div>
          <div class="swiper-slide"></div>
          <div class="swiper-slide"></div>
          <div class="swiper-slide"></div>
          <div class="swiper-slide"></div>
          <div class="swiper-slide"></div>
          <div class="swiper-slide"></div>
          <div class="swiper-slide"></div>
          <div class="swiper-slide"></div>
          <div class="swiper-slide"></div>
          <div class="swiper-slide"></div>
          <div class="swiper-slide"></div>
          <div class="swiper-slide"></div>
          <div class="swiper-slide"></div>
          <div class="swiper-slide"></div>
          <!-- <div class="swiper-slide"></div> -->
          <!-- <div class="swiper-slide">slider12</div>
          <div class="swiper-slide">slider13</div>
          <div class="swiper-slide">slider14</div> -->
        </div>
      </div>
      <!-- ---------------------------------------------------------  -->

      <!-- ---------------------------------------------------------- -->
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
import { handleJump } from "@/utils";
import { DeepClone } from "@/utils/tool";
export default {
  props: {
    seckill: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      handleJump,
      brand_adv: [],
      brand_swiper: [],
      animateUp: false,
      timer: null,
    };
  },
  mounted() {
    // this.Lunbo();
    new Swiper(".container3", {
      loop: true,
      slidesPerView: 3, // 一组三个
      spaceBetween: 10, // 间隔
      autoplay: {
        delay: 5000, // 每次轮播间隔的ms数，默认3000ms
        stopOnLastSlide: false, // 播放完最后一张图片后是否停留在最后一张图片上，停止继续轮播。默认false
      },
    });
    this.$api("home.getBrand").then((res) => {
      this.brand_adv = res.data.brand_adv;
      this.brand_swiper = res.data.brand_swiper;
      // if (this.brand_swiper.length >= 10) {
      //   this.timer = setInterval(this.scrollAnimate, 2000);

      // new Swiper(".container3", {
      //   loop: true,
      //   autoplay: true,
      //   slidesPerView: 10, // 一组10个
      //   spaceBetween: 10, // 间隔
      // });

      new Swiper(".swiper", {
        loop: true,
        slidesPerView: 10,
        spaceBetween: 10,
        // autoplay: true,
        autoplay: {
          delay: 3000, // 每次轮播间隔的ms数，默认3000ms
          stopOnLastSlide: false, // 播放完最后一张图片后是否停留在最后一张图片上，停止继续轮播。默认false
        },
        // loopedSlides: 5,
      });
      // }
    });
  },
  methods: {
    // Lunbo() {
    //   var swiper = new Swiper(".swiper-container-demo", {
    //     slidesPerView: 3,
    //     breakpoints: {
    //       750: {
    //         slidesPerView: 1,
    //       },
    //       990: {
    //         slidesPerView: 2,
    //       },
    //     },
    //     spaceBetween: 0,
    //     loop: true,
    //     speed: 1000,
    //     autoplay: true,
    //     autoplayDisableOnInteraction: false,
    //     disableOnInteraction: true,
    //     observer: true,
    //     observeParents: true,
    //     pagination: ".swiper-pagination",
    //     paginationClickable: true,
    //     mousewheelControl: true,
    //     debugger: true,
    //     navigation: {
    //       nextEl: ".swiper-button-next",
    //       prevEl: ".swiper-button-prev",
    //     },
    //   });
    // },
    click(e) {
      this.$api("home.getClick", { adv_id: e.id });
      handleJump(e.jump);
    },
    scrollAnimate() {
      this.animateUp = true;
      setTimeout(() => {
        this.brand_swiper.push(this.brand_swiper[0]);
        this.brand_swiper.shift();
        this.animateUp = false;
      }, 500);
    },
    handleMore() {
      let arr = DeepClone(this.brand_swiper);
      let arr1 = this.shuffle(arr);
      this.brand_swiper = arr1;
    },
    shuffle(array) {
      var j, x, i;
      for (i = array.length; i; i--) {
        j = Math.floor(Math.random() * i);
        x = array[i - 1];
        array[i - 1] = array[j];
        array[j] = x;
      }
      return array;
    },
    toBrand() {
      this.$router.push("/index/brand");
    },
  },
  destroyed() {
    if (this.timer) clearInterval(this.timer);
  },
};
</script>

<style lang="less" scoped>
.animate-up {
  transition: all 0.5s ease-in-out;
  transform: translateX(-110px);
}
.bottom {
  width: 100%;
  overflow: hidden;
  height: 405px;
  background: #ffffff;
  border-radius: 4px;
  margin-top: 20px;
  .tile_wrap {
    // width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px 20px;
    .img-title {
    }
    .img {
      width: 29px;
      height: 29px;
      margin-right: 5px;
    }
    .flex-r {
      display: flex;
      flex-direction: row;
    }
    span {
      font-size: 22px;
      font-family: CKTKingKong;
      font-weight: bold;
      color: #f14a49;
    }
    .right {
      display: flex;
      align-items: center;
      .more {
        width: 9px;
        height: 16px;
        margin-left: 10px;
      }
      span {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
      }
    }
  }
  .main {
    padding: 20px;
    padding-top: 0;
    .top_img {
      display: flex;
      justify-content: space-between;
      .img {
        width: 380px;
        height: 190px;
        border-radius: 4px;
      }
    }
    .wrapper {
      width: 100%;
      width: 1140px;
      display: flex;
      margin-top: 20px;
      // overflow: hidden !important;
      // animation: slide 30s linear infinite;
      .img {
        flex-shrink: 0;
        width: 100px;
        height: 100px;
        border-radius: 8px;
        margin-right: 6px;
        border: 1px solid #f2f2f2;
        box-sizing: border-box;
        &:hover {
          box-shadow: 0px 0px 10px 0px rgba(221, 221, 221, 0.6);
        }
      }
    }
    // @keyframes scroll {
    //   0% {
    //     transform: translateX(0);
    //   }
    //   100% {
    //     transform: translateX(-100%);
    //   }
    // }
    // @keyframes slide {
    //   0% {
    //     transform: translateX(0);
    //   }
    //   100% {
    //     transform: translateX(-40%); /* 根据盒子宽度和数量调整 */
    //   }
    // }
  }
}
.swiper-container {
  // width: 786px;
  margin-top: 20px;
  // width: 1140px;
  // width:calc(100% - 20px);
  padding: 0px 5px;
  height: 110px;
}
.swiper-wrapper {
  width: 100px;
  height: 110px;
}
.item_wrap {
  .item {
    width: 114px;
    height: 100px;
    background: #ffffff;
    // border: 1px solid #f63535;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    box-sizing: border-box;
    .img {
      flex-shrink: 0;
      width: 100px;
      height: 100px;
      border-radius: 8px;
      margin-right: 10px;
      border: 1px solid #f2f2f2;
      box-sizing: border-box;
      &:hover {
        box-shadow: 0px 0px 10px 0px rgba(221, 221, 221, 0.6);
      }
    }
  }
}

.swiper {
  .swiper-wrapper {
    // .item {
    //   width: 114px;
    //   height: 100px;
    //   background: #ffffff;
    //   // border: 1px solid #f63535;
    //   box-sizing: border-box;
    //   border-radius: 4px;
    //   display: flex;
    //   flex-direction: column;
    //   align-items: center;
    //   cursor: pointer;
    //   box-sizing: border-box;
    .img {
      flex-shrink: 0;
      width: 100px;
      height: 100px;
      border-radius: 8px;
      margin-right: 10px;
      border: 1px solid #f2f2f2;
      box-sizing: border-box;
      &:hover {
        box-shadow: 0px 0px 10px 0px rgba(221, 221, 221, 0.6);
      }
    }
    // }
  }
}
</style>
