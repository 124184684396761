<template>
  <div class="six" :style="{ backgroundImage: `url(${data.floor[0].bj_img})` }">
    <div class="tile_wrap">
      <div class="img">
        <img :src="data.floor[0].icon" alt="" />
      </div>
      <!-- <div class="right c_p" @click="handleJump(data.floor[0].jump)"> -->

      <div class="right c_p" @click="handleMore(data.floor[0])">
        <span style="font-size: 16px; cursor: pointer; font-weight: bold; color: #f14948">更多</span>
        <div class="more">
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="item" v-for="item in data.floor[0].goodsArr" :key="item.id" @click="handleDetail(item.id)">
        <div class="goods_img">
          <img :src="item.image.url" alt="" />
        </div>
        <div class="flex-r">
          <p class="p1">
            ￥{{ item.price }}
            <span v-if="item.price1" style="text-decoration: line-through; color: rgb(116, 113, 113); font-size: 12px">{{ item.price1 }}</span>
          </p>
          <!-- <p class="p1 title-qhj">{{ item.price_qhj }}</p> -->
        </div>
        <p class="title-qhj">{{ item.price_qhj }}</p>
        <!-- <span v-if="item.price1 > 0" style="text-decoration: line-through; color: rgb(116, 113, 113); font-size: 12px">{{ item.price1 }}</span> -->
        <!-- <p class="p1">
          ￥{{ item.price }}
          <span v-if="item.price1 > 0" style="text-decoration: line-through; color: rgb(116, 113, 113); font-size: 12px">{{ item.price1 }}</span>
        </p> -->
        <p class="p2 shenglue_1" style="height: 30px">{{ item.title }}</p>
        <!-- <div class="tag">国家码</div> -->
        <tagcom :tags="item.tags"></tagcom>
        <p class="p3 shenglue_1">{{ item.manufacturer }}</p>
        <p class="p3 shenglue_1">规格：{{ item.specification }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { handleJump } from "@/utils";
import tagcom from "@/components/tag.vue";
export default {
  components: {
    tagcom,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      handleJump,
    };
  },
  methods: {
    handleDetail(id) {
      const { href } = this.$router.resolve({
        path: "/index/goodsDetail",
        query: { id },
      });
      window.open(href, "_blank");
    },
    handleMore(item) {
      // let firstTab = this.$store.state.pageName;
      // console.log("面包屑---", firstTab);
      console.log("首页楼层-------", item);
      this.$store.commit("CHANGE_PAGENAME", []);
      if (item.jump.url == "/index/allGoods") {
        this.$store.commit("CHANGE_TAB", 1);
        localStorage.setItem("mid", 21);
      }
      handleJump(item.jump);
    },
  },
};
</script>

<style lang="less" scoped>
.six {
  width: 100%;
  border-radius: 4px;
  margin-top: 20px;
  .tile_wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 20px;
    box-sizing: border-box;
    .img {
      width: 120px !important;
      height: 29px !important;
    }
    .right {
      display: flex;
      align-items: center;
      .more {
        width: 9px;
        height: 16px;
        margin-left: 10px;
      }
      span {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
      }
    }
  }
  .main {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-around;
    .item {
      width: 232px;
      margin-right: 4px;
      background: #ffffff;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      padding: 15px;
      box-sizing: border-box;
      cursor: pointer;
      &:nth-child(-n + 5) {
        margin-bottom: 10px;
      }
      &:hover {
        box-shadow: 0px 0px 20px 0px rgba(221, 221, 221, 0.8);
      }
      .goods_img {
        margin: 0 auto;
        margin-top: 10px;
        width: 170px;
        height: 170px;
        background: #eeeeee;
        border-radius: 4px;
      }
      .p1 {
        font-size: 16px;
        font-weight: bold;
        color: @priceRed;
        margin-top: 18px;
      }
      .p2 {
        font-size: 14px;
        font-weight: bold;
        color: #333333;
        line-height: 3;
      }
      .p3 {
        font-size: 12px;
        font-weight: 400;
        color: #666666;
        line-height: 1.8;
      }
      .tag {
        width: 46px;
        height: 20px;
        background: #3dbb2b;
        border-radius: 2px;
        font-size: 12px;
        font-weight: 400;
        color: #fefefe;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 8px;
      }
      .flex-r {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      .title-qhj {
        color: rgb(255, 76, 76);
        font-size: 13px;
        // margin-left: 10px;
        font-weight: bold;
        // position: absolute;
        // right: 10px;
      }
    }
  }
}
</style>
