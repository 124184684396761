<template>
  <div class="inner" :style="{ width: coupon.list.length ? '816px' : '1200px' }">
    <div class="tile_wrap">
      <div class="left">
        <div class="img">
          <img src="@/assets/img/index/miao.png" alt="" />
        </div>
        <span class="seckillview">{{ seckill.title }}</span>
        <div class="timev">
          <span class="timev_1">活动剩余时间:{{ time.d }}</span>
          <span class="timev_2">天</span>
          <span class="timev_1">{{ time.h }}</span>
          <span class="timev_2">时</span>
          <span class="timev_1">{{ time.m }}</span>
          <span class="timev_2">分</span>
          <span class="timev_1">{{ time.s }}</span>
          <span class="timev_2">秒</span>
        </div>
      </div>

      <div class="right c_p" @click="handleMore(seckill)">
        <span style="font-size: 16px; cursor: pointer; font-weight: bold; color: #f14948">更多</span>
        <div class="more">
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
    </div>

    <div class="swiper-container container2" :style="{ width: coupon.list.length ? '786px' : '1120px' }">
      <div class="item_wrap swiper-wrapper">
        <div class="item swiper-slide" v-for="(item, i) in seckill.goods" :key="i" @click="handleDetail(item.id)">
          <div class="img">
            <img :src="item.image.url" alt="" />
          </div>
          <p class="title shenglue_1">{{ item.title }}</p>
          <p class="shenglue_1">{{ item.specification }}</p>
          <p class="shenglue_1 mb_20">{{ item.manufacturer }}</p>
          <div class="price">
            <p>
              <span>￥{{ item.price }}</span> <span v-if="item.price1 != ''" style="text-decoration: line-through; color: rgb(116, 113, 113); font-size: 12px">{{ item.price1 }}</span>
            </p>
            <div><span>抢</span></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
import { handleJump } from "@/utils";
export default {
  props: {
    seckill: {
      type: Object,
      default: () => {},
    },
    coupon: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      handleJump,
      time: {
        d: 0,
        h: 0,
        m: 0,
        s: 0,
      },
      setInt: [],
    };
  },
  // watch: {
  //   $route(to) {
  //     console.log('1111111跳转',to)

  //   }
  // },
  mounted() {
    new Swiper(".container2", {
      loop: true,
      slidesPerView: this.coupon.list.length > 0 ? 4 : 6, // 一组三个
      spaceBetween: 10, // 间隔
      autoplay: {
        delay: 5000, // 每次轮播间隔的ms数，默认3000ms
        stopOnLastSlide: false, // 播放完最后一张图片后是否停留在最后一张图片上，停止继续轮播。默认false
      },
    });

    this.setInt = setInterval(() => {
      this.formatDateToYYYYMMDDHHMMSS(this.seckill.jssj);
      // console.log(this.time.d,this.time.h,this.time.m,this.time.s)
    }, 1000);
  },

  methods: {
    formatDateToYYYYMMDDHHMMSS(dateStr) {
      const date = new Date(dateStr);
      const dateL = Number(Date.parse(date) - Date.parse(new Date())) / 1000;
      // console.log(dateL)
      this.time.d = Math.floor(dateL / 24 / 60 / 60);
      this.time.h = Math.floor((dateL - 24 * 60 * 60 * this.time.d) / 60 / 60);
      this.time.m = Math.floor((dateL - 24 * 60 * 60 * this.time.d - 60 * 60 * this.time.h) / 60);
      this.time.s = Math.floor(dateL - 24 * 60 * 60 * this.time.d - 60 * 60 * this.time.h - 60 * this.time.m);
    },
    handleDetail(id) {
      const { href } = this.$router.resolve({
        path: "/index/goodsDetail",
        query: { id },
      });
      window.open(href, "_blank");
    },
    handleMore(item) {
      // let firstTab = this.$store.state.pageName;
      // console.log("面包屑---", firstTab);
      console.log("首页---", item);
      this.$store.commit("CHANGE_PAGENAME", ["首页", item.title]);
      if (item.url.url == "/index/allGoods") {
        this.$store.commit("CHANGE_TAB", 1);
        localStorage.setItem("mid", 21);
      }
      handleJump(item.url);
    },
  },
  beforeDestroy() {
    clearInterval(this.setInt);
  },
  destroyed() {
    clearInterval(this.setInt);
  },
};
</script>

<style lang="less" scoped>
.swiper-container {
  width: 786px;
  // width: 1140px;
  // width:calc(100% - 20px);
  padding: 0px 5px;
  height: 301px;
}
.swiper-wrapper {
  width: 188px;
  height: 301px;
}

.inner {
  width: 816px;
  // width: 1200px;
  // width:100%;
  height: 389px;
  background: #fff;
  border-radius: 4px;
  margin-top: 20px;
  .tile_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 20px;
    .left {
      display: flex;
      align-items: center;
      .img {
        width: 29px;
        height: 29px;
        margin-right: 10px;
      }
      .seckillview {
        font-size: 24px;
        font-family: Alimama ShuHeiTi;
        font-weight: bold;
        font-style: italic;
        color: #f14948;
      }
    }
    .right {
      display: flex;
      align-items: center;
      .more {
        width: 9px;
        height: 16px;
        margin-left: 10px;
      }
      span {
        line-height: 100%;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
      }
    }
  }
  .item_wrap {
    .item {
      width: 188px;
      height: 301px;
      background: #ffffff;
      border: 1px solid #f63535;
      box-sizing: border-box;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      box-sizing: border-box;
      .img {
        width: 150px;
        height: 150px;
        background: #eeeeee;
        border-radius: 4px;
        margin-top: 10px;
      }
      p {
        padding: 0 10px;
        font-size: 12px;
        font-weight: 400;
        color: #666666;
        line-height: 1.8;
      }
      .title {
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        line-height: 2;
        margin-bottom: 4px;
      }
      .price {
        // width: 188px;
        width: 100%;
        height: 36px;
        background: linear-gradient(90deg, #ff6d17 0%, #ef3517 80%);
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        p {
          font-size: 16px;
          line-height: 36px;
          font-weight: bold;
          color: #fefefe;
        }
        div {
          width: 56px;
          height: 36px;
          background: #feeb47;
          font-size: 16px;
          font-weight: bold;
          color: #6e1f11;
          position: relative;
          span {
            position: absolute;
            right: 10px;
            top: 8px;
          }
          &::before {
            position: absolute;
            left: 0;
            bottom: 0;
            content: "";
            width: 0;
            height: 0;
            border: 18px solid #ef3517;
            border-top-color: transparent;
            border-right-color: transparent;
          }
        }
      }
    }
  }

  .timev {
    margin-left: 10px;
    font-family: "PingFang SC", Arial, "Microsoft YaHei", sans-serif;
    font-style: normal;
    color: #f14948;

    .timev_1 {
      font-size: 24px;
    }

    .timev_2 {
      font-size: 24px;
    }
  }
}
</style>
